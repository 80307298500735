@import './colors.css';

.row_margin_top {
    margin-top: 1rem;
}

.switch {
    cursor: pointer;
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color);
}

.save_span {
    float: right;
}

.test_success_icon {
    font-size: 1.5rem;
    color: var(--approved--color);
}

.test_error_icon {
    font-size: 1.5rem;
    color: var(--declined--color);
}

.show_hide_key_icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #6c757d;
}