.icons_box {
    position: absolute;
    z-index: 10;
    top: -3px;
    left: -17px;
}

.image_box {
    position: relative;
    max-width: 104px;
    max-height: 104px;
}

.image_src {
    max-width: 100% !important;
    max-height: 100%;
    cursor: pointer;
}

.upload_image_box {
    opacity: 0.7;
    color: #007bff;
    cursor: pointer;
}

.upload_image_icon {
    color: white;
    cursor: pointer;
}

.delete_image_set {
    top: -10px
}

.delete_image_box {
    opacity: 0.7;
    color: #dc3545;
    cursor: pointer;
}

.delete_image_icon {
    color: white;
    cursor: pointer;
}

.upload_image_button_error {
    width: 104px;
    height: 104px;
    border: 1px dashed #dc3545;
    background-color: #fafafa;
    text-align: center;
    border-radius: 4px;
    vertical-align: top;
}

.upload_image_button {
    width: 104px;
    height: 104px;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
    text-align: center;
    border-radius: 4px;
    vertical-align: top;
}

.upload_image_error_message {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}
