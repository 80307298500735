.wrapper_style_normal {
    border: 1px solid #d9d9d9;
    ;
}

.wrapper_style_error {
    border: 1px solid #ff4d4f;
}

.toolbar_style {
    border-top-color: white;
    border-right-color: white;
    border-left-color: white;
    border-bottom-color: #d9d9d9;
    z-index: 1;
}

.editor_style {
    height: 20rem;
    overflow-x: scroll;
    overflow-y: scroll;
}